import React from "react"
import styled from 'styled-components'
import { useStaticQuery, graphql, Link } from "gatsby"
import { SectionLabel } from "src/components/layout"


const LatestPostsSection = styled.div `
  margin: 4rem 0;
`

const PostUnit = styled.div `
  margin-top: 1.75rem;
`

const PostUnitTitle = styled(Link) `
  font-family: Lato;
  font-weight: bold;
  text-decoration: none;
  font-size: 1.25rem;
  color: #C02907;

`

const PostUnitSubtitle = styled.p `
  margin-top: 0.5rem;
  font-family: 'Lato';
  font-size: 1.125em;
  margin-bottom: 0;
`


const LatestPosts = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}
          filter: { frontmatter: { type: { eq: "post" },  draft: { ne: true } } }, limit: 4 ) {
          totalCount
          edges {
            node {
              id
              frontmatter{
                title
                subtitle
                date(formatString: "YYYY")
                type
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `
  )

  return (
    <LatestPostsSection>
      <SectionLabel>Latest Posts</SectionLabel>
      {allMarkdownRemark.edges.map(({ node }) => (  
        <PostUnit>
          <PostUnitTitle to={node.fields.slug}>{node.frontmatter.title}</PostUnitTitle>
          <PostUnitSubtitle>{node.frontmatter.subtitle}</PostUnitSubtitle>
        </PostUnit>
      ))}
    </LatestPostsSection>
  )
}

export { LatestPosts }