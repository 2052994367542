import React from "react"
import styled from 'styled-components'
import { useStaticQuery, graphql } from "gatsby"
import { FictionItem } from "src/components/fiction"
import { SectionLabel } from "src/components/layout"

const StoriesSection = styled.div `
  margin: 3rem 0;

  @media (min-width: 1024px) {
    margin: 4rem 0;
  }
`

const RecentStories = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}
          filter: { frontmatter: { type: { eq: "fiction" } } }, limit: 5 ) {
          totalCount
          edges {
            node {
              id
              frontmatter{
                title
                date(formatString: "YYYY")
                type
                thumbnail {
                  childImageSharp {
                    fixed(width: 276) {
                      width
                      height
                      src
                      srcSet
                    }
                  }
                }
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `
  )

  return (
    <StoriesSection>
      <SectionLabel>Stories</SectionLabel>
      {allMarkdownRemark.edges.map(({ node }) => (  
        <FictionItem 
          title={node.frontmatter.title}
          byLine="By Cristina Hartmann"
          coverImage={node.frontmatter.thumbnail && node.frontmatter.thumbnail.childImageSharp.fixed.src}
          path={node.fields.slug}
          date={node.frontmatter.date}
        />
      ))}
    </StoriesSection>
  )
}

export { RecentStories }