import React from "react"
import styled from 'styled-components'
import { Link } from "gatsby"
import read_icon from "src/images/read_icon.svg"

const Container = styled.div `
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  margin-top: 2rem;

  @media (min-width: 1024px) {
    margin-top: 2rem;
  }
`

const Cover = styled(Link) `
  display: block;
  /* flex-basis: 138px; */
  height: 200px;
  flex-shrink: 1;
  position: relative;

  &:after {
    border: 1px solid rgba(0, 0, 0, 0.1);
    content: '';
  
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`

const CoverImg = styled.div `
  width: 138px;
  height: 200px;
  background-image: url(${props => props.src});
  background-size: cover;

  @media (min-width: 1024px) {
    width: 138px;
    height: 200px;
  }
`

const FictionDetails = styled.div `
  margin: 0.25rem 0rem 0.25rem 1.5rem;
  background-size: cover;
  /* flex: 100%; */

  @media (min-width: 1024px) {
    margin: 0.25rem 2rem;
  }
`

const FictionLabel = styled.div `
  color: #C02907;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 0.5rem;
`

const FictionTitle = styled(Link) `
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.25;
  /* text-transform: uppercase; */
  /* color: #2F4659; */
  color: #000;
  text-decoration: none;
  /* letter-spacing: 1.25px; */

  &:hover {
    color: #2F4659;
  }
`

const FictionByline = styled.div `
  color: #000;
  font-size: 1.125rem;
  margin-top: 0.5rem;
`

const FictionDate = styled.div `
  color: #aaa;
  font-size: 1rem;
  margin-top: 0.75rem;
  /* font-weight: bold; */
`

const ReadButton = styled(Link) `
  font-family: 'Helvetica Neue', 'Open Sans';
  text-shadow: 0px 1px 1px #fff;
  margin-top: 1.5rem;
  background-image: linear-gradient(180deg, #FFF6BE 0%, #FFD56C 100%);
  border: 1px solid #FFDD70;
  border-radius: 2rem;
  padding: 0.75rem 2rem;
  font-size: 1.25rem;
  color: #000;
  font-weight: 700;
  text-align: center;
  float: left;
  text-decoration: none;
  display: none;
`

const ReadIcon = styled.span `
  width: 25px;
  height: 18px;
  background-image: url(${read_icon});
  margin: 6px 0.5rem 0 0;
  float: left;
`

const ReadLabel = styled.span `
  margin: 0 0 0 0;
`

export const FictionItem = (props) => {
  return (
    <Container>
      <Cover to={props.path}>
        <CoverImg src={props.coverImage} />
      </Cover>
      <FictionDetails>
        <FictionLabel>Fiction</FictionLabel>
        <FictionTitle to={props.path}>{props.title}</FictionTitle>
        <FictionByline>{props.byLine}</FictionByline>
        <FictionDate>{props.date}</FictionDate>
        <ReadButton to={props.path}>
          <ReadIcon /><ReadLabel>Read</ReadLabel>
        </ReadButton>
      </FictionDetails>
    </Container>
  )
}