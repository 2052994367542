import React from "react"
import styled from 'styled-components'

import profile_photo from "src/images/profile_photo.jpg"


const SocialSection = styled.div `
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
  border-top: 2px solid #f2f2f2;
  border-bottom: 2px solid #f2f2f2;
  margin: -1.5rem;
  background: #f7f7f7;

  @media (min-width: 1024px) {
    margin-top: 1rem;
    border: 0 none;
    padding: 0;
    background: none;
  }
`

const ProfileImg = styled.img `
  margin: 0 auto;
  border-radius: 100%;
  width: 100px;
`

const Blurb = styled.p `
  margin: 1.5rem 0 3rem;
  text-align: center;
  font-size: 1.25rem;
  color: #284360;
  padding: 0 2.5rem;

  & > a {
    text-decoration: none;
    color: #284360;
    font-weight: 700;
    font-style: italic;
  }
`


const SocialAside = () => {
  return (
    <SocialSection>
      <ProfileImg src={profile_photo} />
      <Blurb>
        Follow me on <a href="https://twitter.com/cmmhartmann" target="_blank" rel="nofollow noopener noreferrer">Twitter</a> and <a href="https://medium.com/@cmmhartmann" target="_blank" rel="nofollow noopener noreferrer">Medium</a>
      </Blurb>
    </SocialSection>
  )
}

export { SocialAside }