import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { Layout, MainContainer, AsideContainer } from "../components/layout"
import { RecentStories } from "src/components/recent_stories"
import { LatestPosts } from "src/components/latest_posts"
import { NowReadingAside } from "src/components/asides/now_reading"
import { PreviousReadsAside } from "src/components/asides/previous_reads"
import { SocialAside } from "src/components/asides/social"
import styled from "styled-components"


const IntroSection = styled.section `
  margin: 0rem 0;
  display: block;
`

const IntroTitle = styled.h1 `
  font-weight: 300;
  color: #000;
  /* font-style: italic; */
  line-height: 1.25;
  font-size: 2.5rem;
  margin: 0 0 2.5rem 0;

  @media (min-width: 1024px) {
    font-size: 2.25rem;
    line-height: 1.5;
    text-align: center;
    margin: 0 3rem 2.5rem;
  }

`

const IntroP = styled.p `
  font-size: 1.25rem;
  color: #000;

  & > a {
    color: #C22700;
    text-decoration: none;
    padding-bottom: 0.125rem;
    border-bottom: 1px solid #C22700;
  }
`

export default ({ data }) => (
  <Layout>
    <Helmet>
      <title>Home | {data.site.siteMetadata.title}</title>
    </Helmet>
    <MainContainer>
      <IntroSection>
        <IntroTitle>Stringing words together and hoping for the best
        </IntroTitle>
        <IntroP>
        Hi, I’m Cristina, a writer and bibliophile in Pittsburgh. My work explores our identities and relationships through disability and immigrant experiences. 
        Welcome! 
        </IntroP>
        <IntroP>
        In 2020, I received the June 2020 Deaf Artists Award at the Anderson Center and was <a href="https://mastersreview.com/2021-summer-short-story-award-shortlist/" target="_blank" rel="noreferrer">shortlisted</a> for <i>The Masters Review</i>'s summer 2021 Short Story Award. My work has appeared or is forthcoming in <i>McSweeney's</i>, <i>The MacGuffin</i>, <i>descant</i>, <i>Kaleidoscope</i>, and elsewhere.
        </IntroP>
        <IntroP>
          Now that we've gotten the boring stuff out of the way, here's the important stuff: I love cuddly scarves, cheap port, and first-person narratives. I will talk books anywhere, anytime, and at any opportunity.
        </IntroP>
        <IntroP>
        Here you can find links to my published stories and my sporadic ramblings on all things literary.
        </IntroP>
      </IntroSection>
      <LatestPosts />
      <RecentStories />
    </MainContainer>
    <AsideContainer>
      <SocialAside />
      <NowReadingAside />
      <PreviousReadsAside />
    </AsideContainer>
  </Layout>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`